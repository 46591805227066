const config = {
    siteTitle: "Chatty NFT | Chat with your favorite NFTs!",
    siteName: "Chatty NFT",
    siteDescription: "Unlock a new way to engage with your NFTs with Chatty NFT! Chat with your favorite digital collectibles using advanced AI technology. Discover a unique, interactive experience today!",
    siteKeywords: "NFTs, chat with NFT, non-fungible token, cryptocurrency, blockchain, AI chat, nft merch, nft art community, digital currency, crypto investment, AI characters, blockchain technology",
    path: "chattynft",
    logoUrl: "https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg",
    favicon: "https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg",
    shopUrl: "https://shop.andys.place",
    app_id: "663ec8c3f3c5b211d3cd75e9",
    app_url: "https://chattynft.com",
    twitter: "@ChattyNFT",
    
    /*apiUrl: "http://localhost:8080",
    stytch_login_url: "http://localhost:3000/loggingin",
    stytch_public_token: "public-token-test-87fd74cf-f520-4946-a3cd-32f67dde94aa",*/

    stytch_login_url: "https://app.chattynft.com/loggingin",
    apiUrl: "https://andy-back-end-loi4p.ondigitalocean.app",
    stytch_public_token: "public-token-live-b7c04a5d-12b5-4e2a-a4ad-c93e4d0a1953",
  };
  
  export default config;