import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentTool, setCurrentTool } from '../features/currentTool/currentToolSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidebarHome from '../components/SidebarHome';
import ChatComponent from '../components/ChatComponent';
import NFTChatComponent from '../components/NFTChatComponent';
import MerchSidebar from '../components/MerchSidebar';
import { close } from '../features/mobile/menuSlice';
import { faCircleInfo, faCircleXmark, faDollarSign, faImages, faRobot, faStore } from '@fortawesome/free-solid-svg-icons';
import config from '../config/config';
import ToolsModal from '../components/ToolsModal';
import { openToolsModal } from '../features/tools/toolsModalSlice';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { setUser, selectUser } from '../features/user/userSlice';
import { addToast } from '../features/ui/uiSlice';
import axios from 'axios';
import { showModal } from '../features/ui/uiSlice';

const apiURL = config.apiUrl;

function Home() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isMenuOpen = useSelector(state => state.menu.isOpen);
  const fetchedTools = useSelector((state) => state.tools.items);
  const currentTool = useSelector(selectCurrentTool);
  if (!currentTool && fetchedTools?.length > 0) {
    dispatch(setCurrentTool(fetchedTools[0]));
  }
  const [isFavorite, setIsFavorite] = useState(
    user.favorites?.generators?.includes(currentTool?._id)
  );

  useEffect(() => {
      setIsFavorite(user.favorites?.generators?.includes(currentTool?._id));
  }, [user, currentTool]);

  const toggleFavorite = async () => {
    let updatedFavorites;
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);
    try {
        await axios.post(`${apiURL}/favorites/update`, {
            dynamic_id: user.dynamic_id,
            item_id: currentTool._id,
            type: 'generators',
            isFavorite: newFavoriteStatus,
        });
        dispatch(addToast(newFavoriteStatus ? 'Favorite Added!' : 'Favorite Removed!', 'success'));

        if (newFavoriteStatus) {
            updatedFavorites = {
                ...user.favorites,
                ['generators']: [...user.favorites['generators'], currentTool._id],
            };

        } else {
            updatedFavorites = {
                ...user.favorites,
                ['generators']: user.favorites['generators'].filter((id) => id !== currentTool._id),
            };
        }
        const updatedUser = {
            ...user,
            favorites: updatedFavorites,
        };
        dispatch(setUser(updatedUser));
      } catch (error) {
        setIsFavorite(!newFavoriteStatus);
        dispatch(addToast('Favorite NOT updated! Please try again.', 'error'));
      }
  };
  /*const isLaptopOrDesktop = useIsLaptopOrDesktop();
    const userWalletDesktop = useUser();  // Assuming this hook can be safely called regardless of device
    const userWalletMobile = useActiveWallet();  // Assuming this too
    let userAddress;
    if (isLaptopOrDesktop) {
        userAddress = userWalletDesktop?.user?.address;
    } else {
        userAddress = userWalletMobile?.getAccount()?.address;
    }*/
  //const userWallet = useActiveWallet();
    //const userAddress = userWallet?.getAccount().address;
    /*const userWallet = useUser();
    const userUser = userWallet?.user;
    const userAddress = userUser?.address;*/

  if (fetchedTools.length === 0 || !fetchedTools.tools.length > 0 || !fetchedTools.assistants > 0) return <span>Loading...</span>;

  return (
    <>
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
        <meta name="keywords" content={config.siteKeywords} />
        <link rel="icon" type="image/png" href={config.favicon} />
        <link rel="canonical" href={config.app_url} />
        <meta property="og:title" content={config.siteTitle} />
        <meta property="og:description" content={config.siteDescription} />
        <meta property="og:image" content={config.favicon} />
        <meta property="og:url" content={config.app_url} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={config.siteName} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.siteTitle} />
        <meta name="twitter:description" content={config.siteDescription} />
        <meta name="twitter:image" content={config.favicon} />
        <meta name="twitter:site" content={config.twitter} />
        <meta name="twitter:creator" content={config.twitter} />
      </Helmet>
      <div className='flex flex-col min-w-0 md:flex-row w-full gap-2' style={{ height: '84vh' }}>
        <div className={`fixed inset-0 z-10 p-8 hidden xl:relative xl:flex xl:flex-col xl:w-1/6 xl:min-w-fit xl:rounded xl:mx-auto border border-primary`}>
          <SidebarHome />
          
        </div>
        {/*<div className="md:hidden flex-grow min-w-0">
          !userAddress && (<>
            <div className='w-full flex flex-col mb-2'>
              <div className='text-xl font-bold text-inverted text-center bg-primary rounded'>Welcome to Andy's Place!</div>
              <div className='px-4 mt-2'>
                <p>Whether you want to play awesome games, chat with hilarious AI characters, or shop for sweet merch, you're in the right place!</p>
                <p className='mt-2'>Just like ANDY, Andy's Place lives on the blockchain, so you'll need to connect your wallet to create your account and get started.</p>
              </div>
            </div>
            <div className='flex flex-row items-center justify-center mb-6 mt-4'>
              <div className=''>
                 <img src='https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png' alt='Andy Base Avatar' className='mx-auto w-20 h-auto' />
              </div>
              
              <div className='ml-4 text-2xl text-primary font-bold'>
                It's cool, man.
              </div>
            </div>
            <div className="border rounded bg-primary border-gray-100 mt-3 p-3 text-sm text-black">
            <span className='font-bold text-2xl leading-none text-inverted'>How to get started at Andy's Place!?</span>
            <ul className='list-disc list-inside mt-2'>
              <li><span className='font-bold'>Connect Wallet:</span> Access Andy's exclusive AI tools and games. Start with 5K FREE Credits. Buy more credits at any time.</li>
              <li><span className='font-bold'>No Wallet? No Worries!</span> Still explore the Merch Shop, Shill Shack and More!</li>
            </ul>
          </div>

          <div className='w-full hidden lg:flex flex-col justify-center mt-3 border border-black rounded'>
            <ConnectButtonComponent />
          </div>
          <div className='w-full flex lg:hidden flex-col justify-center mt-3 border border-black rounded'>
            <ConnectButtonMobileComponent />
          </div>
          
          <div className="mt-3 pt-3 pb-10">
          
            <Link to='https://shop.andys.place' target='_blank' className="block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faStore} className='mr-2' />Andy's Merch Shop
            </Link>
            <Link to='/shillshack' onClick={() => {dispatch(close())}} className="mt-2 block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faImages} className='mr-2' />Shill Shack
            </Link>
            <button className='mt-2 mb-2 block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold' onClick={()=>{dispatch(openModalIFrame({ url:'https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022&swapAmount=' })); dispatch(close());}}>         
                <FontAwesomeIcon icon={faDollarSign} className='mr-2' />Buy Andy on SushiSwap
          </button>
            <Link to={`/`} className="w-full justify-center flex items-center px-1 py-3 hover:bg-gray-700 hover:text-gray-200 focus:outline-none border rounded mb-2 mt-2" onClick={() => {dispatch(openToolsModal()); dispatch(close());}}>
                View All Tools
            </Link>
            
          </div>
        </>)
        </div>*/}
        <div className="xl:hidden flex-grow min-w-0">
          <div className='flex flex-row items-center justify-center gap-2'>
            <button onClick={toggleFavorite} className="p-1 rounded border border-primary w-1/5 mb-2 hover:bg-gray-700 hover:text-gray-200">
                <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} className="text-yellow-400"/>
            </button>
            <button onClick={() => dispatch(showModal(currentTool.description))} className="p-1 rounded border border-primary w-1/5 mb-2 hover:bg-gray-700 hover:text-gray-200">
                <FontAwesomeIcon icon={faCircleInfo} className=""/>
            </button>
            <Link to={`/`} className="text-poetsen w-3/5 justify-center flex items-center px-1 py-1 border border-primary hover:bg-gray-700 hover:text-gray-200 focus:outline-none rounded mb-2" onClick={() => {dispatch(openToolsModal()); dispatch(close());}}>
                View All Tools
            </Link>
          </div>
          {currentTool?._id === '66365d3efec2b358df7f572a' ? <NFTChatComponent /> : <ChatComponent />}
          {/*<ChatComponent />*/}
        </div>
        <div className="hidden xl:flex flex-grow min-w-0">
          {currentTool?._id === '66365d3efec2b358df7f572a' ? <NFTChatComponent /> : <ChatComponent />}
          {/*<ChatComponent />*/}
        </div>
        <div className="w-full hidden xl:block md:w-1/6 md:min-w-64 p-4 border border-primary mt-2 md:mt-0 overflow-auto rounded">
            <button onClick={toggleFavorite} className="text-poetsen py-2 rounded bg-primary w-full mb-6">
                <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} className="text-yellow-400 mr-2"/>Add to Favorites
            </button>
          <MerchSidebar />
        </div>
      </div>
      <ToolsModal />
    </>
  );
}

export default Home;

