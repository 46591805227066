// src/components/PackageModal.js
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPackages } from '../features/packages/packageSlice';
import PurchaseCreditsComponent from '../components/PurchaseCreditsComponent';

function PackagePage() {
  const packages = useSelector(selectPackages);

  return (
    <div className={`w-full rounded py-5`}>
      <div className="mx-auto p-5 border w-full lg:w-2/3 xl:w-1/2 shadow-lg rounded-md bg-primary">
        <div className="mt-3 text-center">
          <h3 className="text-2xl leading-6 font-medium text-poetsen">AI Credits Packages</h3>
          <div className='mt-2'>
            <p className="text-sm">AI credits are required to use and interact with all of the AI tools in this app. You can purchase AI credits as you go to enjoy all of the awesome fun. Credit usage is based on the length of messages both from you to a tool, as well as from a tool back to you.</p>
            <p className=""><span className='font-bold'>All purchases are handled securely through Stripe.</span></p>
          </div>
          <div className="mt-2">
            {packages.map(packageItem => (
              <div key={packageItem._id} className="flex flex-row items-center p-2 border rounded m-2 bg-white hover:bg-gray-200">
                <div className='w-1/6 hidden md:block md:pl-3'>
                    <img
                    src="https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg"
                    alt="Purchase AI Credits for Chatty NFT"
                    className={`w-16 h-auto`}
                    /> 
                </div>
                <div className='w-1/2'>
                    <p className="text-lg text-gray-500 leading-none">{packageItem.name}</p>
                </div>
                <div className='w-1/2 md:w-2/3 flex flex-col'>
                    <PurchaseCreditsComponent packageItem={packageItem} />
                </div>
                {/*<div className='lg:hidden w-1/2 lg:w-1/3'>
                    <PurchaseCreditsComponentMobile packageItem={packageItem} />
            </div>*/}
                
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePage;
