import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = config.apiUrl;
const path = config.path;
const app_id = config.app_id;

// Async thunk for fetching tool data
export const fetchTools = createAsyncThunk('tools/fetchTools', async () => {
    const response = await axios.get(`${apiURL}/fetch/fetchApp/${app_id}`);
    console.log('crypto tools response.data: ', response.data);
    return response.data.data;
  });

const toolSlice = createSlice({
  name: 'tools',
  initialState: {
    items: {
      tools: [],
      assistants: []
    },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTools.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTools.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchTools.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default toolSlice.reducer;
