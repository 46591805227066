import React, {useContext, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidebarHome from './SidebarHome';
import Header from './Header';
import Footer from './Footer';
import { close } from '../features/mobile/menuSlice';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(state => state.menu.isOpen);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar now takes full vertical space alongside the main content */}
      {/*<Sidebar />*/}
      
      {/* Wrap the Header and main content in a flex-col container */}
      <div className="flex flex-col flex-1">
        <Header /> {/* Header now sits at the top of the main content area */}
        <main className="flex-1 p-2 md:p-10 overflow-auto min-w-0" id="main-content">
          <div className={`min-w-0 fixed inset-0 bg-black-main z-10 p-8 ${isMenuOpen ? 'flex' : 'hidden'} xl:hidden xl:relative xl:flex xl:flex-col xl:w-1/6 xl:min-w-fit xl:rounded xl:mx-auto xl:border xl:border xl:border-primary xl:bg-black-main xl:text-white`}>
            <SidebarHome />
            <div onClick={() => dispatch(close())} className='bg-primary rounded ml-3 flex items-center justify-center p-2 cursor-pointer'>
              <button onClick={() => dispatch(close())} className="xl:hidden text-black"><FontAwesomeIcon icon={faCircleXmark} /><br/>Close</button>
            </div>
            
          </div>
          {children} {/* Main content */}
          <Footer />
        </main>
        
      </div>
    </div>
  );
};

export default Layout;

