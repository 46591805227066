import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import config from '../config/config';
import { openModalIFrame } from '../features/modals/modaliframeSlice';
import { useNavigate } from 'react-router-dom';
import CardListAddDelete from '../components/CardListAddDelete';
import CardListFavoriteTools from '../components/CardListFavoriteTools';
import DashboardPopularNFTs from '../components/DashboardPopularNFTs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faFire, faGear, faImages, faRobot, faStore } from '@fortawesome/free-solid-svg-icons';

function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
  
    return (
      <div className='flex flex-col items-center justify-center'>
        {user ? (<>
          <div className='flex flex-col lg:flex-row rounded w-full lg:w-4/5 mb-6 p-6 items-center bg-primary'>
            <div className='lg:w-1/5 flex justify-center mb-3 lg:mb-0'>
              <img src={user.pic} alt={user.name} className='w-24 h-24 rounded-full border-2 border-gray-900' />
            </div>
            <div className='flex flex-col w-full lg:w-3/5'>
              <div className='text-3xl mb-2'>Welcome, {user.name ? user.name : user.username}!</div>
              <div className='text-lg'>Email: {user.email}</div>
              <div className='text-lg'>Username: {user.username}</div>
              <div className='text-lg'>Credits: {Math.round(user?.ai_credits)}</div>
            </div>
            <div className='flex flex-col w-full lg:w-1/5'>
              <div className='text-lg mt-2 lg:mt-0 lg:text-right'>It is currently:</div>
              <div className='text-lg lg:text-right'>{new Date().toLocaleString('en-US')}</div>
              <Link to="/settings" className='w-full'>
                <button className='w-full bg-primary hover:bg-gray-500 text-inverted hover:text-white font-bold py-2 px-4 rounded mt-4'><FontAwesomeIcon icon={faGear} className='mr-2' />Edit Profile</button>
              </Link>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row lg:flex-wrap mb-6 lg:w-4/5 lg:justify-between'>
            <div className='flex flex-col lg:flex-row lg:flex-wrap mb-6 w-full lg:w-3/5 lg:justify-between'>
              <Link to='/' className="mt-0 text-poetsen lg:m-0 w-full lg:w-full border border-secondary text-xl lg:text-3xl text-center px-5 py-3 lg:py-6 lg:px-24 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <img
                    src="https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg"
                    alt="Chatty NFT Chat Space"
                    className={`w-16 lg:w-32 h-auto mx-auto`}
                  />
                Go to the Chat Space
              </Link>
            </div>
            <div className='flex flex-col lg:flex-row lg:flex-wrap mb-6 ml-4 lg:w-1/3 lg:justify-start lg:gap-4'>
              <button className='lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold' onClick={()=>{navigate('/packages');}}>         
                  <FontAwesomeIcon icon={faRobot} className='mr-2' />Buy AI Credits
              </button>
              <button to='/nftchat' className="mt-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold" onClick={()=>{navigate('/nftchat');}}>
                  <FontAwesomeIcon icon={faImages} className='mr-2' />My NFTs
              </button>
              <button to='/browsenfts' className="mt-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold" onClick={()=>{navigate('/browsenfts');}}>
                  <FontAwesomeIcon icon={faFire} className='mr-2 text-orange-400' />Browse Popular NFTs
              </button>
              <Link to='https://shop.andys.place' target='_blank' className="flex items-center mt-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                  <FontAwesomeIcon icon={faStore} className='mr-2' />Merch Shop
              </Link>
            </div>
          </div>
          <div className="flex flex-col w-full lg:flex-row lg:flex-nowrap lg:h-96 lg:w-4/5 gap-5">
            <div className="w-full lg:w-1/3 rounded h-96">
              <CardListFavoriteTools items={user.favorites?.generators} title='Your Favorite Tools' />
            </div>
            <div className="w-full lg:w-2/3 bg-primary rounded h-96">
              <DashboardPopularNFTs />
            </div>
          </div>
          {/*<div>
            <p>User ID: {user._id}</p>
            <p>User Email: {user.email}</p>
            <p>Username: {user.username}</p>
            <p>Name: {user.name}</p>
            <p>Profile Picture: {user.pic}</p>
            <p>Dynamic ID: {user.dynamic_id}</p>

            <p>OpenAI: {user.api_keys.openai}</p>
            <p>Perplexity: {user.api_keys.perplexity}</p>
            <p>Stable Diffusion: {user.api_keys.stablediffusion}</p>
            <p>Leonardo: {user.api_keys.leonardo}</p>
          </div>*/}
        </>) : (
          <p>No user is logged in.</p>
        )}
      </div>
    );
  }

export default Dashboard;
