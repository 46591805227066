import {
    ThirdwebProvider,
    ConnectButton,
    darkTheme,
    //defineChain,
  } from "thirdweb/react";
  import {
    createWallet,
    walletConnect,
  } from "thirdweb/wallets";
  import { base } from "thirdweb/chains";
  import { createThirdwebClient } from "thirdweb";
  import { get_user } from "../api/userFunctions";
  import { useDispatch, useSelector } from 'react-redux';
  import { setUser } from "../features/user/userSlice";

const client = createThirdwebClient({ clientId: '841cd7c05f6572ec017b34f5ce3b0920'});

const wallets = [
    createWallet("io.metamask"),
    createWallet("com.coinbase.wallet"),
    walletConnect(),
    createWallet("com.trustwallet.app"),
    createWallet("io.zerion.wallet"),
    createWallet("me.rainbow"),
    createWallet("app.phantom"),
  ];

  /*const displayBalanceToken = {
    [base.chainId]: "0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022",
  };*/

const ConnectButtonMobileComponent = () => {
    const dispatch = useDispatch();
  return (
    <ThirdwebProvider>
      <ConnectButton
        client={client}
        wallets={wallets}
        theme={darkTheme({
          colors: {
            accentButtonBg: "#2A80F8",
            secondaryText: "#FE3831",
            accentText: "#2A80F8",
            borderColor: "#FE3831",
            separatorLine: "#FE3831",
            primaryButtonBg: "#FE3831",
          },
        })}
        connectButton={{
          label: "Connect Wallet",
        }}
        onConnect={(user) => {
          const userAddress = user?.getAccount().address;
          console.log('userAddress in connect button: ', userAddress);
          /*get_user(userAddress).then((res) => {
            console.log('Get user response in connect button: ', res);
            dispatch(setUser(res));
          });*/
        }}
        connectModal={{
          size: "wide",
          title: "Connect & Sign In",
          titleIcon:
            "https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg",
          welcomeScreen: {
            img: {
              src: "https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg",
              width: 150,
              height: 150,
            },
            title:
              "Connect your wallet to get started chatting.",
            subtitle:
              "Enter a world of conversations that you never thought possible.",
          },
          showThirdwebBranding: false,
        }}
      />
    </ThirdwebProvider>
  );
}

export default ConnectButtonMobileComponent;