// src/features/currentTool/currentToolSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const currentToolSlice = createSlice({
  name: 'currentTool',
  initialState: {
    value: null, // Initial state of the current tool is null
  },
  reducers: {
    // Action to update the current tool
    setCurrentTool: (state, action) => {
      state.value = action.payload;
      console.log('Current tool set to:', action.payload);
    },
    // Action to clear the current tool (optional)
    clearCurrentTool: (state) => {
      state.value = null;
    },
  },
});

// Export the actions
export const { setCurrentTool, clearCurrentTool } = currentToolSlice.actions;

// Export the selector to access the current tool state
export const selectCurrentTool = (state) => state.currentTool.value;

// Export the reducer
export default currentToolSlice.reducer;
