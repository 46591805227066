// src/components/Header.js
import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/user/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faMapMarkerAlt, faStore, faHouse, faChartLine, faRobot, faWalkieTalkie, faCommentDots, faRightToBracket, faPuzzlePiece, faFire } from '@fortawesome/free-solid-svg-icons';
import ThemeSwitcher from './ThemeSwitcher';
import HamburgerMenu from './HamburgerMenu';
import config from '../config/config';
import { fullLogout } from '../api/userFunctions';
import { selectSession, deleteSession } from '../features/session/sessionSlice';
import '../index.css';

const Header = () => {
    const [isTalking, setIsTalking] = useState(false);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const session = useSelector(selectSession);
    const displayPath = pathname.toUpperCase().substring(1);
    
    const handleLogout = async () => {
        const loggingOut = await fullLogout(session);
        dispatch(logout());
        dispatch(deleteSession());
        navigate('/login');
    };

    useEffect(() => {
        const toggleTalking = () => {
          setIsTalking(true);
          setTimeout(() => {
            setIsTalking(false);
          }, 1200);
        };
        toggleTalking();
        const intervalId = setInterval(toggleTalking, 7000);
        return () => clearInterval(intervalId);
      }, []);

return (<>
    <div className="flex xl:justify-between items-center p-4 pt-1 xl:pt-2 pb-2 border-b border-primary text-primary text-poetsen">
        {session && <div className='xl:hidden w-1/4'>
            <HamburgerMenu />
        </div>}
        {!session && <div onClick={()=> navigate('/login')} className='xl:hidden w-1/4'>
            <FontAwesomeIcon icon={faRightToBracket} className='text-xl bg-primary text-inverted rounded-full p-3 cursor-pointer' />
        </div>}
        <div className="flex justify-end xl:justify-start xl:items-center flex-row font-bold text-primary xl:w-1/3">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 hidden xl:block" />
            <span className='hidden xl:block'>{'/ ' + (displayPath || 'HOME')}</span>
        </div>
        
        
        <div onClick={()=> navigate('/')} className="cursor-pointer text-center flex flex-row items-center justify-center xl:justify-center space-x-4 w-1/2 xl:w-1/3">
            <img
                  src="https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg"
                  alt="Avatar"
                  className={`w-16 h-auto border border-primary bg-primary rounded ${isTalking ? '' : ''}`}
                /> 
            <span className='text-5xl text-primary font-bold text-poetsen hidden xl:block'>{config.siteName}</span>
            
                <div className='text-poetsen text-2xl mx-auto xl:hidden'>{config.siteName}</div>
            
        </div>
        <div className='flex flex-col justify-end items-end space-y-2 w-1/4 xl:w-1/3'>
        {session && <div className="flex-row items-center space-x-4 justify-end hidden xl:flex">
                <button onClick={()=> navigate('/dashboard')} className="flex items-center hover:text-gray-500">
                    <FontAwesomeIcon icon={faHouse} />
                    <span className="ml-2">Home</span>
                </button>
                <button onClick={()=> navigate('/')} className="flex items-center hover:text-gray-500">
                    <FontAwesomeIcon icon={faCommentDots} />
                    <span className="ml-2">Chat</span>
                </button>
                
                <button onClick={()=> navigate('/browsenfts')} className="flex items-center hover:text-gray-500">
                    <FontAwesomeIcon icon={faFire} className='text-orange-400' />
                    <span className="ml-2">Popular</span>
                </button>
                <Link to='https://shop.andys.place' target='_blank' className="flex items-center hover:text-gray-500">
                    <FontAwesomeIcon icon={faStore} />
                    <span className="ml-2">Merch</span>
                </Link>
                <ThemeSwitcher />
                {/*<a href='https://www.dextools.io/app/en/base/pair-explorer/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840?t=1712845998905' alt='Chart for Andy on Base' target='_blank' rel='noreferrer noopener' className="flex items-center hover:text-blue-400">
                    <button className="flex items-center hover:text-gray-700">
                        <FontAwesomeIcon icon={faChartLine} />
                        <span className="ml-2">Chart</span>
                    </button>
</a>*/}
                
                
            </div>}
            <div className='flex flex-row items-center justify-end'>
            {session && <button onClick={()=>navigate('/packages')} className=" hidden xl:flex items-center hover:text-gray-500">
                    <FontAwesomeIcon icon={faRobot} />
                    <span className="ml-2">Buy Credits</span>
                </button>}
                
                {/*<span className='text-xl text-inverted font-bold'>Wallet: {walletBalance} $ANDY</span>
                <img
                  src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png"
                  alt="Avatar"
                  className={`w-8 h-5 ml-2 mr-2`}
            /> */}
                {session && <button onClick={() => handleLogout()} className="flex items-center mr-4 ml-2 text-inverted border border-primary rounded-full p-1 bg-primary">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </button>}
                {/*!session && <div onClick={()=> navigate('/login')} className={`mt-2 hidden xl:block xl:ml-10 bg-yellow-400 hover:bg-blue-500 cursor-pointer text-black hover:text-white px-3 py-2 rounded`}>
                    <FontAwesomeIcon icon={faRightToBracket} className='mr-2' />LOGIN TO ANDY'S PLACE
        </div>*/}
                
            </div>
        </div>
    </div>
    {/*<div className='hidden flex flex-row xl:hidden items-center justify-center'>
        <span className='text-xl text-inverted font-bold'>Wallet: {walletBalance} $ANDY</span>
    </div>*/}
</>);
};

export default Header;
