import React, { useState } from 'react';
import { getNFTMetadata } from '../api/userFunctions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNFTChat } from '../features/currentTool/currentNFTChatSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointer } from '@fortawesome/free-regular-svg-icons';

function NFTSearchForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contractAddress, setContractAddress] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [nft, setNFT] = useState(null);

  /*const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submitting: ', contractAddress, tokenId);
    onSubmit(contractAddress, tokenId);
  };*/

const handleSubmit = async (e) => {
    e.preventDefault();
    const fetchNft = await getNFTMetadata(contractAddress, tokenId);
    console.log('nft: ', fetchNft);
    setNFT(fetchNft.data);
    };

const handleSetCurrentNFT = (nft) => {
    dispatch(setCurrentNFTChat(nft));
    };

  return (<>
    <form onSubmit={handleSubmit} className="flex flex-col w-full p-5 gap-4 justify-center items-center">
      <input
        type="text"
        placeholder="Contract Address"
        value={contractAddress}
        onChange={(e) => setContractAddress(e.target.value)}
        className="p-2 border rounded w-full md:w-96 text-black border border-primary"
        required
      />
      <input
        type="text"
        placeholder="Token ID"
        value={tokenId}
        onChange={(e) => setTokenId(e.target.value)}
        className="p-2 border rounded w-full md:w-96 text-black border border-primary"
        required
      />
      <button type="submit" className="bg-body hover:bg-gray-700 hover:text-white font-bold py-2 px-4 rounded">
        <FontAwesomeIcon icon={faHandPointer} className='mr-2' />FIND NFT
      </button>
    </form>
        {nft && (
            <div className="flex flex-col w-full md:w-2/3 bg-primary justify-center items-center gap-4 border p-5 rounded">
                <h2 className="text-xl font-bold">{nft.name || nft.contract?.name}</h2>
                <img src={nft.image?.thumbnailUrl || nft.image?.originalUrl} alt={nft.name || nft.contract?.name} className="w-32 h-32 rounded shadow-xl" />
                <p>{nft.description || nft.contract?.description || nft.contract?.openSeaMetadata?.description}</p>
                <button onClick={() => {handleSetCurrentNFT(nft); navigate('/')}} className="bg-body hover:bg-gray-700 hover:text-white font-bold rounded p-2 mt-2">
                    CHAT WITH THIS NFT
                  </button>
            </div>
        )}
    </>);
}

export default NFTSearchForm;
