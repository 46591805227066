import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleServiceStatus } from '../features/services/servicesSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const CheckboxOption = ({ id, label, image_url, onChange, isChecked }) => {
    
  return (
    <div className="flex items-center">
      <label className="relative flex cursor-pointer items-center rounded-full p-3" data-ripple-dark="true">
        <input
          id={id}
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-green-500 checked:bg-green-500 checked:before:bg-green-500 hover:before:opacity-10"
        />
        <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="1"
            >
                <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
                ></path>
            </svg>
        </div>
      </label>
      <div className="ml-2 flex items-center mb-2">
        <img src={image_url} alt="Option" className="mb-1 h-10 w-10 object-cover rounded-full mr-3" />
        <label className="cursor-pointer select-none font-light text-gray-700" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
};

const CheckboxCard = ({ options }) => {
    const servicesData = useSelector((state) => state.services.data);
    const dispatch = useDispatch();
    console.log('servicesData', servicesData)
    console.log('options', options)
    const [checkedState, setCheckedState] = useState(
      options.map(option => servicesData[option.id] ?? option.isChecked)
  );
  useEffect(() => {
    setCheckedState(options.map(option => servicesData[option.id] ?? option.isChecked));
}, [servicesData, options]);

const handleOnChange = (optionId) => {
  // Find the index of the option in the options array
  const position = options.findIndex(option => option.id === optionId);
  if (position !== -1) {
      const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      
      // Dispatch the toggle service status action
      dispatch(toggleServiceStatus({ service: optionId }));
  }
};

    /*const [checkedState, setCheckedState] = React.useState(
        options.length > 0
            ? options.map(option => option.isChecked || false)
            : []
    );

    const handleOnChange = (position, optionId) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        console.log('position', position);
        console.log('optionId', optionId);
        setCheckedState(updatedCheckedState);
        dispatch(toggleServiceStatus({ service: optionId }));
    };*/

  return (
    <div className="w-full p-5 mb-8 mx-auto bg-white shadow-lg rounded-lg relative">
        <Link
        to="/settings"
        className="absolute top-4 right-4 px-4 py-2 bg-primary text-inverted rounded-md hover:bg-gray-300 transition-colors duration-300"
        >
        <FontAwesomeIcon icon={faKey} className='mr-2 text-inverted' />Manage API Keys
        </Link>
        {options.map((option, index) => (
        <CheckboxOption
            key={option.id}
            id={option.id}
            label={option.label}
            image_url={option.image_url}
            isChecked={checkedState[index]}
            onChange={() => handleOnChange(option.id)}
        />
        ))}
    </div>
  );
};

export default CheckboxCard;
