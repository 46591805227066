import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faGlobe, faMicrochip, faPaperPlane, faX, faImages, faDollarSign, faStore, faRobot, faFire } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { openModalIFrame } from '../features/modals/modaliframeSlice';
import { openModal } from '../features/packages/modalPackagesSlice';
import config from '../config/config';

const siteName = config.siteName;

const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  return (
    <div className="flex w-full flex-col justify-center md:flex-row md:justify-between items-center mt-20 pt-10 border-t bg-black-main text-white rounded">
      <div className="p-4 w-full md:w-1/5 flex flex-col text-poetsen">
        {/*<Link to='https://www.dextools.io/app/en/base/pair-explorer/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840?t=1712845998905' alt='Chart for Andy on Base' target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faChartLine} className='mr-2' />Andy's Chart
        </Link>
        <Link to='https://t.me/AndyOnBasePortal' alt='Telegram Group for Andy on Base' target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faPaperPlane} className='mr-2' />Andy's Telegram Group
        </Link>*/}
        <Link to='https://x.com/ChattyNFT' alt='Chatty NFT on X Twitter' target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faX} className='mr-2' />Chatty NFT On X (Twitter)
        </Link>
        <Link to='https://app.gaim.ai' alt='Andy on Basescan' target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faMicrochip} className='mr-2' />GAIM.AI App
        </Link>
        <Link to='https://gaimnetwork.com' alt='Andy official website' target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faGlobe} className='mr-2' />GAIM Network Home
        </Link>
      </div>
      <div className="p-4 w-full md:w-3/5 flex flex-col justify-center text-poetsen">
        <div className='md:flex flex-col items-center justify-center hidden'>
            <img
                    src="https://chatty-nft.nyc3.cdn.digitaloceanspaces.com/chattyNFTLogo500x500bg.jpg"
                    alt="Chat with your favorite NFTs on Chatty NFT"
                    className={`w-24 h-auto mr-2 border-2 border-primary rounded-full`}
                /> 
            <span className='text-5xl text-primary font-bold hidden md:block'>{siteName}</span>
        </div>
        <div className='mt-8' style={{color: '#99abd6'}}>
            <a href='https://gaim.ai' alt='GAIM.AI' target='_blank' rel='noreferrer noopener' className="flex flex-col text-center items-center">
                <img
                    src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
                    alt="Powered by GAIM.AI"
                    className={`w-8 h-8`}
                    /> 
                    Powered by GAIM.AI
            </a>
        </div>
      </div>
      <div className="p-4 w-full md:w-1/5 flex flex-col text-poetsen">
            <button className='block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold' onClick={()=>{navigate('/packages');}}>         
                <FontAwesomeIcon icon={faRobot} className='mr-2' />Buy AI Credits
            </button>
            <Link to='/nftchat' className="mt-2 block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faImages} className='mr-2' />My NFTs
            </Link>
            <Link to='/browsenfts' className="mt-2 block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faFire} className='mr-2 text-orange-400' />Browse Popular NFTs
            </Link>
            <Link to='https://shop.andys.place' target='_blank' className="mt-2 block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faStore} className='mr-2' />Merch Shop
            </Link>
            
            {/*<button className='mt-2 block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold' onClick={()=>{dispatch(openModalIFrame({ url:'https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022&swapAmount=' }));}}>         
                <FontAwesomeIcon icon={faDollarSign} className='mr-2' />Buy Andy on SushiSwap
  </button>*/}
            
      </div>
    </div>
  );
};

export default Footer;